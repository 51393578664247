// import React from 'react'
import logo1 from './img/logo1.png'
import React, { createElement, useState } from 'react'
import { Link } from 'react-router-dom'
import icon23 from './assets/images/icon/icon-23.png'
import icon24 from './assets/images/icon/icon-24.png'
import icon25 from './assets/images/icon/icon-25.png'

const Footer = () => {
    const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
        if (scrolled < 100) {
			setVisible(false)
		}
		else if (scrolled > 100) {
			setVisible(true)
		}
		else if (scrolled <= 100) {
			setVisible(false)
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
			/* you can also use 'auto' behaviour
			   in place of 'smooth' */
		});
	};

	window.addEventListener('scroll', toggleVisible);
	
    return (
        <div>
            {/* <!-- Start Footer Area --> */}
            <footer className="footer-area bg-f9fafb pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-footer-widget active">
                                <a href="#" className="logo">
                                    <div className="text-center">
                                        <img src={logo1} alt="Image" width="150" />
                                    </div>
                                </a>
                                <p className="justify">With your support, Countless Girls  will receive Health Care, Education and a Happy Life. Start your monthly donation today.</p>

                                <ul className="social-link">
                                    <li>
                                        <h3>Follow us:</h3>
                                    </li>

                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/AkodaFundraise1" target="_blank">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/" target="_blank">
                                            <i className="ri-youtube-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Services</h3>

                                <ul className="import-link">
                                    <li>
                                        <Link to="/about-us">
                                            About
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/contact-us">
                                            My account
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li> */}
                                     <li>
                                        <Link to="/be-a-volunteer">
                                           Volunteer
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">
                                            Privacy policy
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-and-conditions">
                                            Terms of service
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                  </ul>
                                  
                            </div>
                            <div className="single-footer-widget active">
                            <p style={{lineHeight:"0.8"}}><b>supported by...</b></p>
                            <img src={icon23} alt="Image" />                            
                            <img src={icon25} alt="Image" />
                            <img src={icon24} alt="Image" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Import Links</h3>

                                <ul className="import-link">
                                    <li>
                                        <Link to="/gallery">
                                            Gallery
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>

                                    </li>
                                    
                                    <li>
                                        <Link to="/donate-now">
                                            Donation
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">
                                            Contact us
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                       <li>
                                        <Link to="/careers">
                                        Careers
                                            <i className="ri-arrow-right-line"></i>
                                        </Link>
                                    </li>
                                 
                                </ul>
                            </div>
                        </div>
{/* 
                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Find us</h3>

                                <ul className="address">
                                    <li className="location">
                                   
                                    <p> <i className="ri-map-pin-line"></i> Vittal Rao Nagar, <li> KTC Illumination, Opposite Westin Hotel, mindspace  Hyderabad, 500081,India</li></p>

                                    </li>

                                    <li>
                                        <i class="ri-mail-line"></i>
                                       <span className="__cf_email__" 
                                            data-cfemail="462e232a2a29062a2333226825292b"> info@akoda.org</span> 
                                    </li>
                                
                                    <li>
                                    <i class="ri-phone-line"></i>
                                        <span  className="call-us" > +91 9642383838</span>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            {/* <!-- End Footer Area --> */}

            {/* <!-- Start Copy Right Area --> */}
            <div className="copy-right-area bg-f9fafb" >
                <div className="container">
                    <p>
                        Copyright <i className="ri-copyright-line"></i> 2023 AkõDa. Designed By
                        <Link to="/" target="_blank"> AkõDa </Link>
                    </p>
                </div>
            </div>
            {/* <!-- End Copy Right Area --> */}
            <div className="go-top active">
				<i className="ri-arrow-up-s-fill" ></i>
				<i className="ri-arrow-up-s-fill" onClick={scrollToTop}
					style={{ display: visible ? 'inline' : 'none' }}></i>
			</div>

			{/* <!-- End Go Top Area --> */}
        </div>
    )
}

export default Footer