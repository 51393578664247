import React from 'react'
import './assets/css/remixicon.css'
import './assets/css/bootstrap.min.css'
import './assets/css/meanmenu.min.css'
import './assets/css/animate.min.css'
import './assets/css/magnific-popup.min.css'
import './assets/css/odometer.min.css'
import './assets/css/tab-style.css'
import './assets/css/navbar.css'
import './assets/css/responsive.css'
import './assets/css/style.css'
import './assets/css/flaticon.css'
import { Link } from 'react-router-dom'
import logo1 from './img/logo1.png'

const Nav = () => {
	return (
		<div>
			<nav className="navbar-area navbar  navbar-expand-lg navbar-light "  style={{ backgroundColor: '#efefef ' }}>
				<div className="container" style={{ zIndex: '2', position: 'initial' }}>
					<Link className="navbar-brand" to="/">
						<img src={logo1} alt="logo1" width="190" />
					</Link>
					<button className="navbar-toggler "  type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon "  ></span>
					</button>
					<div className="collapse navbar-collapse mean-menu"  id="navbarNav"  >
						<ul className="navbar-nav  m-auto" >
							<li className="nav-item">
								<Link to="/" className="nav-link" >Home</Link>
							</li>
							<li className="nav-item">
								<Link to="/about-us" className="nav-link">About Us</Link>
							</li>
							<li className="nav-item">
								<Link to="/fundraisers" className="nav-link">AkõDa Fundraisers</Link>
							</li>
							<li className="nav-item">
								<Link to="/crowdfunding" className="nav-link">Crowdfunding</Link>
							</li>
							<li className="nav-item">
								<Link to="/careers" className="nav-link">Careers</Link>
							</li>
							{/* <li className="nav-item">
								<Link to="/support-our-village" className="nav-link">Support Our Village</Link>
							</li> */}
							{/* <li className="nav-item">
								<Link to="/be-a-volunteer" className="nav-link">Volunteer</Link>
							</li>
							<li className="nav-item">
								<Link to="/gallery" className="nav-link">Gallery</Link>
							</li> */}
							<li className="nav-item">
								<Link to="/contact-us" className="nav-link">Contact Us</Link>
								{/* <a href="contact.html" className="nav-link"> Contact Us </a> */}
							</li>
						</ul>
						<div className="others-options">
							<ul className="navbar-nav m-auto">
								{/* <li>
									<button type="button" className="search-icon" data-bs-toggle="modal"
										data-bs-target="#exampleModalsrc" style={{ backgroundColor: 'transparent', color: 'transparent' }}>
										<i className="ri-search-line" style={{ color: '#0286CD', marginRight: '18px', fontSize: '24px' }}></i>
									</button>
								</li> */}
								<li>
									{/* <!-- Button trigger modal --> */}
									<Link to="/donate-now">	<button type="button" className="default-btn glowing" >
										Donate Now
									</button></Link>
									{/* <a href="#" className="">
									</a> */}
								</li>
							</ul>
						</div>
						<Link to="/Sign-in"><button type="button" className="nav-link " >
							Sign in
						</button></Link>
					</div>
				</div>
			</nav>
			{/* <!-- Start Navbar Area --> */}
			<div className="navbar-area">
				<div className="others-option-for-responsive">
					<div className="container">
						<div className="dot-menu">
							<div className="inner">
								<div className="circle circle-one"></div>
								<div className="circle circle-two"></div>
								<div className="circle circle-three"></div>
							</div>
						</div>
						<div className="container">
							<div className="option-inner">
								<div className="others-options justify-content-center d-flex align-items-center">
									<ul>
										<li>
											<button type="button" className="search-icon" data-bs-toggle="modal"
												data-bs-target="#exampleModalsrc" >
												<i className="ri-search-line"></i>
											</button>
										</li>
										<li>
											<Link to=""><button type="button" className="default-btn glowing" data-bs-toggle="modal"  >
												Donate Now
											</button></Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Navbar Area --> */}
			{/* <!-- Search Modal --> */}
			<div className="modal fade search-modal-area" id="exampleModalsrc">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button type="button" data-bs-dismiss="modal" className="closer-btn">
							<i className="ri-close-line"></i>
						</button>
						<div className="modal-body">
							<form className="search-box">
								<div className="search-input">
									<input type="text" name="Search" placeholder="Search for..." className="form-control" />
									<button type="submit" className="search-btn">
										<i className="ri-search-line"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Search Modal --> */}
		</div>
	)
}
export default Nav